import React, { FunctionComponent } from "react";

import Layout from "../../common/layout/layout";
import SEO from "../../common/seo";
import { Link } from "gatsby";

const CritiquesPage: FunctionComponent = () => (
  <Layout backUrl="/about" backText="About">
    <SEO title="Critiques" />
    <div className="lg-half-width">
      <h1>Elda Fezzi</h1>
      <p>
        Osservando da vicino e con una certa continuità, il lavoro di Nino
        Desirelli, si ha la netta misura di quale sia stata ed sia tuttora la
        persuasione – quasi la fascinazione – che la pittura astratta esercita
        sulla mente e sui sensi di chi la pratica...{" "}
        <Link to="/about/critiques/elda-fezzi" style={{ color: "black" }}>
          [more]
        </Link>
      </p>
      <h1>Luigi Lambertini</h1>
      <p>
        Sulle prime tutto pare esatto, al millimetro, definito e concluso. Nulla
        è lasciato al caso, all’improvvisazione, all’estro di un momento. Ogni
        immagine ha una sua collocazione in quello spazio da cui pare sortire ed
        è una collocazione precisa, a...{" "}
        <Link to="/about/critiques/luigi-lambertini" style={{ color: "black" }}>
          [more]
        </Link>
      </p>
    </div>
  </Layout>
);

export default CritiquesPage;
